define("discourse/plugins/discourse-activity-pub/discourse/lib/discourse-markdown/activity-pub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.allowList(["div.note"]);
    helper.registerOptions(opts => {
      opts.features["activity-pub"] = true;
    });
    helper.registerPlugin(md => {
      md.inline.bbcode.ruler.push("note", {
        tag: "note",
        wrap: "div.note"
      });
      md.block.bbcode.ruler.push("note", {
        tag: "note",
        wrap: "div.note"
      });
    });
  }
});