define("discourse/plugins/discourse-activity-pub/discourse/templates/connectors/composer-action-after/activity-pub-category-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showStatus}}
    <ActivityPubStatus @model={{this.model}} @modelType="composer" />
  {{/if}}
  */
  {
    "id": "lawwQ80H",
    "block": "[[[41,[30,0,[\"showStatus\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\",\"@modelType\"],[[30,0,[\"model\"]],\"composer\"]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"activity-pub-status\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/connectors/composer-action-after/activity-pub-category-status.hbs",
    "isStrictMode": false
  });
});