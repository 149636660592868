define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _service, _getUrl, _decorators, _activityPubActor, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @model}}
    <a
      class={{this.classes}}
      href={{this.href}}
      title={{i18n
        "discourse_activity_pub.discovery.description"
        model_name=@model.name
      }}
      {{did-insert this.subscribe}}
      {{did-insert this.didChangeModel}}
      {{did-update this.didChangeModel @model}}
      {{will-destroy this.unsubscribe}}
    >
      {{d-icon "discourse-activity-pub"}}
      {{i18n "discourse_activity_pub.discovery.label"}}
    </a>
  {{/if}}
  */
  {
    "id": "MRfWyABX",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,3],[16,0,[30,0,[\"classes\"]]],[16,6,[30,0,[\"href\"]]],[16,\"title\",[28,[37,1],[\"discourse_activity_pub.discovery.description\"],[[\"model_name\"],[[30,1,[\"name\"]]]]]],[4,[38,2],[[30,0,[\"subscribe\"]]],null],[4,[38,2],[[30,0,[\"didChangeModel\"]]],null],[4,[38,3],[[30,0,[\"didChangeModel\"]],[30,1]],null],[4,[38,4],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,5],[\"discourse-activity-pub\"],null]],[1,\"\\n    \"],[1,[28,[35,1],[\"discourse_activity_pub.discovery.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@model\"],false,[\"if\",\"i18n\",\"did-insert\",\"did-update\",\"will-destroy\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-nav-item.hbs",
    "isStrictMode": false
  });
  class ActivityPubNavItem extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "visible", [_tracking.tracked]))();
    #visible = (() => (dt7948.i(this, "visible"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "actor", [_tracking.tracked]))();
    #actor = (() => (dt7948.i(this, "actor"), void 0))();
    subscribe() {
      this.messageBus.subscribe("/activity-pub", this.handleActivityPubMessage);
    }
    static #_7 = (() => dt7948.n(this.prototype, "subscribe", [_decorators.bind]))();
    unsubscribe() {
      this.messageBus.unsubscribe("/activity-pub", this.handleActivityPubMessage);
    }
    static #_8 = (() => dt7948.n(this.prototype, "unsubscribe", [_decorators.bind]))();
    didChangeModel() {
      const actor = _activityPubActor.default.findByModel(this.args.model, this.args.modelType);
      if (actor && this.canAccess(actor)) {
        this.actor = actor;
        this.visible = true;
      }
    }
    static #_9 = (() => dt7948.n(this.prototype, "didChangeModel", [_decorators.bind]))();
    canAccess(actor) {
      return this.site.activity_pub_publishing_enabled || actor.can_admin;
    }
    handleActivityPubMessage(data) {
      if (_activityPubActor.actorModels.includes(data.model.type) && this.args.model && data.model.id.toString() === this.args.model.id.toString()) {
        this.visible = data.model.ready;
      }
    }
    static #_10 = (() => dt7948.n(this.prototype, "handleActivityPubMessage", [_decorators.bind]))();
    get classes() {
      let result = "activity-pub-route-nav";
      if (this.visible) {
        result += " visible";
      }
      if (this.active) {
        result += " active";
      }
      return result;
    }
    get href() {
      if (!this.actor) {
        return;
      }
      const path = this.site.activity_pub_publishing_enabled ? "followers" : "follows";
      return (0, _getUrl.default)(`${_activityPubActor.actorClientPath}/${this.actor.id}/${path}`);
    }
    get active() {
      return this.router.currentRouteName.includes(`activityPub.actor`);
    }
  }
  _exports.default = ActivityPubNavItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ActivityPubNavItem);
});