define("discourse/plugins/discourse-activity-pub/discourse/controllers/activity-pub-actor-followers", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _computed, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubActorFollowers extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "order", [_tracking.tracked], function () {
      return "";
    }))();
    #order = (() => (dt7948.i(this, "order"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "asc", [_tracking.tracked], function () {
      return null;
    }))();
    #asc = (() => (dt7948.i(this, "asc"), void 0))();
    queryParams = ["order", "asc"];
    static #_3 = (() => dt7948.g(this.prototype, "hasActors", [(0, _computed.notEmpty)("actors")]))();
    #hasActors = (() => (dt7948.i(this, "hasActors"), void 0))();
    loadMore() {
      if (!this.loadMoreUrl || this.total <= this.actors.length) {
        return;
      }
      this.set("loadingMore", true);
      return (0, _ajax.ajax)(this.loadMoreUrl).then(response => {
        if (response) {
          this.follows.pushObjects(response.actors);
          this.setProperties({
            loadMoreUrl: response.meta.load_more_url,
            loadingMore: false
          });
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_4 = (() => dt7948.n(this.prototype, "loadMore", [_object.action]))();
  }
  _exports.default = ActivityPubActorFollowers;
});