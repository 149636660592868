define("discourse/plugins/discourse-activity-pub/discourse/routes/preferences-activity-pub", ["exports", "@ember/service", "discourse/lib/utilities", "discourse/routes/restricted-user"], function (_exports, _service, _utilities, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PreferencesActivityPubRoute extends _restrictedUser.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    showFooter = true;
    setupController(controller, user) {
      if (!this.site.activity_pub_enabled) {
        return this.router.transitionTo(`discovery.${(0, _utilities.defaultHomepage)()}`);
      }
      controller.set("authorizations", user.activity_pub_authorizations);
    }
  }
  _exports.default = PreferencesActivityPubRoute;
});