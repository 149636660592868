define("discourse/plugins/discourse-activity-pub/discourse/connectors/extra-nav-item/activity-pub-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(attrs, ctx) {
      return ctx.site.activity_pub_enabled;
    },
    setupComponent(attrs, component) {
      let model;
      let modelType;
      if (attrs.category) {
        model = attrs.category;
        modelType = "category";
      }
      if (attrs.tag) {
        model = attrs.tag;
        modelType = "tag";
      }
      component.setProperties({
        model,
        modelType
      });
    }
  };
});