define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub-actor-show", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/models/category", "discourse-common/lib/later", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _tracking, _controller, _object, _computed, _service, _category, _later, _I18n, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPubActorShow extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.inject]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "categoryId", [_tracking.tracked], function () {
      return null;
    }))();
    #categoryId = (() => (dt7948.i(this, "categoryId"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "tag", [_tracking.tracked], function () {
      return null;
    }))();
    #tag = (() => (dt7948.i(this, "tag"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "showForm", [_tracking.tracked], function () {
      return false;
    }))();
    #showForm = (() => (dt7948.i(this, "showForm"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "enabled", [_tracking.tracked], function () {
      return this.actor.enabled;
    }))();
    #enabled = (() => (dt7948.i(this, "enabled"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "saveResponse", [_tracking.tracked], function () {
      return null;
    }))();
    #saveResponse = (() => (dt7948.i(this, "saveResponse"), void 0))();
    modelTypes = (() => [{
      id: "category",
      label: _I18n.default.t("admin.discourse_activity_pub.actor.model_type.category")
    }, {
      id: "tag",
      label: _I18n.default.t("admin.discourse_activity_pub.actor.model_type.tag")
    }])();
    static #_10 = (() => dt7948.g(this.prototype, "saveSuccess", [(0, _computed.equal)("saveResponse", "success")]))();
    #saveSuccess = (() => (dt7948.i(this, "saveSuccess"), void 0))();
    get canSave() {
      return this.showForm;
    }
    get containerClass() {
      return `activity-pub-actor-${this.actor.isNew ? "add" : "edit"}`;
    }
    get titleLabel() {
      let key = this.actor.isNew ? "add" : "edit";
      return _I18n.default.t(`admin.discourse_activity_pub.actor.${key}.label`);
    }
    get enabledLabel() {
      let key = this.enabled ? "enabled" : "disabled";
      return `admin.discourse_activity_pub.actor.${key}.label`;
    }
    goBack() {
      this.router.transitionTo("adminPlugins.activityPub.actor");
    }
    static #_11 = (() => dt7948.n(this.prototype, "goBack", [_object.action]))();
    saveActor() {
      this.saving = true;
      this.actor.save().then(result => {
        if (result?.success) {
          if (this.actor.isNew) {
            this.saving = false;
            return this.router.transitionTo("adminPlugins.activityPub.actorShow", result.actor);
          }
          this.actor = _activityPubActor.default.create(result.actor);
          this.saveResponse = "success";
        } else {
          this.saveResponse = "failed";
        }
        (0, _later.default)(() => {
          this.saveResponse = null;
        }, 3000);
        this.saving = false;
      });
    }
    static #_12 = (() => dt7948.n(this.prototype, "saveActor", [_object.action]))();
    toggleEnabled() {
      if (this.enabled) {
        this.actor.disable().then(result => {
          if (result?.success) {
            this.enabled = false;
          }
        });
      } else {
        this.actor.enable().then(result => {
          if (result?.success) {
            this.enabled = true;
          }
        });
      }
    }
    static #_13 = (() => dt7948.n(this.prototype, "toggleEnabled", [_object.action]))();
    changeCategoryId(categoryId) {
      if (categoryId) {
        this.categoryId = categoryId;
        this.actor.model = _category.default.findById(categoryId);
        this.actor.model_type = "Category";
        this.actor.model_id = categoryId;
        this.showForm = true;
      }
    }
    static #_14 = (() => dt7948.n(this.prototype, "changeCategoryId", [_object.action]))();
    changeTag(tag) {
      if (tag) {
        this.tag = tag;
        this.actor.model_type = "Tag";
        this.actor.model_name = tag;
        this.showForm = true;
      }
    }
    static #_15 = (() => dt7948.n(this.prototype, "changeTag", [_object.action]))();
  }
  _exports.default = AdminPluginsActivityPubActorShow;
});