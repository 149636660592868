define("discourse/plugins/discourse-activity-pub/discourse/templates/connectors/user-preferences-nav/user-nav-preferences-activity-pub", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.site.activity_pub_enabled}}
    <LinkTo @route="preferences.activity-pub">
      {{d-icon "discourse-activity-pub"}}
      <span>{{i18n "user.discourse_activity_pub.title"}}</span>
    </LinkTo>
  {{/if}}
  */
  {
    "id": "uC8kK4I0",
    "block": "[[[41,[30,0,[\"site\",\"activity_pub_enabled\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@route\"],[\"preferences.activity-pub\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[\"discourse-activity-pub\"],null]],[1,\"\\n    \"],[10,1],[12],[1,[28,[35,3],[\"user.discourse_activity_pub.title\"],null]],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"d-icon\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/connectors/user-preferences-nav/user-nav-preferences-activity-pub.hbs",
    "isStrictMode": false
  });
});