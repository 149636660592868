define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-tag-chooser", ["exports", "I18n", "select-kit/components/tag-drop", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _I18n, _tagDrop, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _tagDrop.default.extend({
    classNames: ["activity-pub-tag-chooser"],
    selectKitOptions: {
      hasActor: false
    },
    activityPubFilter(tags) {
      return tags.filter(tag => {
        if (tag === this.tagId) {
          return false;
        }
        const actor = _activityPubActor.default.findByModel(tag, "tag");
        if (this.selectKit.options.hasActor) {
          return !!actor;
        } else {
          return !actor;
        }
      });
    },
    modifyContent(content) {
      return this.activityPubFilter(content);
    },
    modifyNoSelection() {
      if (this.tagId === _tagDrop.NONE_TAG) {
        return this.defaultItem(_tagDrop.NO_TAG_ID, _I18n.default.t("admin.discourse_activity_pub.actor.tag.none"));
      } else {
        return this.defaultItem(_tagDrop.ALL_TAGS_ID, _I18n.default.t("admin.discourse_activity_pub.actor.tag.none"));
      }
    },
    actions: {
      onChange(tagId) {
        this.onChange(tagId);
      }
    }
  });
});