define("discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/string", "discourse-common/helpers/d-icon", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _string, _dIcon, _decorators, _I18n, _activityPubActor, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ActivityPubStatus extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "site", [_service.inject]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "messageBus", [_service.inject]))();
    #messageBus = (() => (dt7948.i(this, "messageBus"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "forComposer", [_tracking.tracked]))();
    #forComposer = (() => (dt7948.i(this, "forComposer"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "ready", [_tracking.tracked]))();
    #ready = (() => (dt7948.i(this, "ready"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "enabled", [_tracking.tracked]))();
    #enabled = (() => (dt7948.i(this, "enabled"), void 0))();
    constructor() {
      super(...arguments);
      this.forComposer = this.args.modelType === "composer";
      const actor1 = this.findActor();
      if (actor1) {
        this.ready = actor1.ready;
        this.enabled = actor1.enabled;
        this.messageBus.subscribe("/activity-pub", this.handleMessage);
        if (this.forComposer && !this.args.model.activity_pub_visibility) {
          this.args.model.activity_pub_visibility = actor1.default_visibility;
        }
      }
    }
    findActor() {
      const category1 = this.forComposer ? this.args.model.category : this.args.model;
      const tags1 = this.forComposer ? this.args.model.tags : [this.args.model];
      let actor1;
      if (category1) {
        actor1 = _activityPubActor.default.findByModel(category1, "category");
      }
      if (!actor1 && tags1) {
        tags1.some(tag1 => {
          if (tag1) {
            actor1 = _activityPubActor.default.findByModel(tag1, "tag");
          }
          return !!actor1;
        });
      }
      return actor1;
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.messageBus.unsubscribe("/activity-pub", this.handleMessage);
    }
    handleMessage(data1) {
      const model1 = data1.model;
      if (model1 && model1.type === this.args.modelType && model1.id === this.args.model.id) {
        this.enabled = model1.enabled;
        this.ready = model1.ready;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "handleMessage", [_decorators.bind]))();
    get active() {
      return this.site.activity_pub_enabled && this.enabled && this.ready;
    }
    get translatedTitle() {
      const args1 = {
        model_type: this.args.modelType
      };
      if (this.active) {
        args1.delay_minutes = this.siteSettings.activity_pub_delivery_delay_minutes;
      }
      return _I18n.default.t(`discourse_activity_pub.status.title.${this.translatedTitleKey}`, args1);
    }
    get translatedTitleKey() {
      if (!this.site.activity_pub_enabled) {
        return "plugin_disabled";
      }
      if (this.args.modelType === "category" && this.args.model.read_restricted) {
        return "category_read_restricted";
      }
      if (!this.enabled) {
        return "model_disabled";
      }
      if (!this.ready) {
        return "model_not_ready";
      }
      if (this.active) {
        if (!this.site.activity_pub_publishing_enabled) {
          return "publishing_disabled";
        }
        return "model_active.first_post";
      } else {
        return "model_not_active";
      }
    }
    get statusKey() {
      if (this.active) {
        return !this.site.activity_pub_publishing_enabled ? "publishing_disabled" : "active";
      } else {
        return "not_active";
      }
    }
    get classes() {
      let result1 = `activity-pub-status ${(0, _string.dasherize)(this.statusKey)}`;
      if (this.args.onClick) {
        result1 += " clickable";
      }
      return result1;
    }
    labelKey(type1) {
      let attribute1 = "status";
      let key1 = this.statusKey;
      if (this.forComposer && this.site.activity_pub_publishing_enabled) {
        attribute1 = "visibility";
        key1 = this.args.model.activity_pub_visibility;
      }
      return `discourse_activity_pub.${attribute1}.${type1}.${key1}`;
    }
    get translatedLabel() {
      return _I18n.default.t(this.labelKey("label"));
    }
    static #_8 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class={{this.classes}} title={{this.translatedTitle}} role="button">
          {{icon "discourse-activity-pub"}}
          <span class="label">{{this.translatedLabel}}</span>
        </div>
      
    */
    {
      "id": "kaVE9ka/",
      "block": "[[[1,\"\\n    \"],[10,0],[15,0,[30,0,[\"classes\"]]],[15,\"title\",[30,0,[\"translatedTitle\"]]],[14,\"role\",\"button\"],[12],[1,\"\\n      \"],[1,[28,[32,0],[\"discourse-activity-pub\"],null]],[1,\"\\n      \"],[10,1],[14,0,\"label\"],[12],[1,[30,0,[\"translatedLabel\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-activity-pub/discourse/components/activity-pub-status.js",
      "scope": () => [_dIcon.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ActivityPubStatus;
});