define("discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-webfinger", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ActivityPubWebfinger = _object.default.extend({});
  ActivityPubWebfinger.reopenClass({
    validateHandle(handle) {
      return (0, _ajax.ajax)({
        url: "/webfinger/handle/validate",
        type: "POST",
        data: {
          handle
        }
      }).then(response => response?.valid).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = ActivityPubWebfinger;
});