define("discourse/plugins/discourse-activity-pub/discourse/controllers/admin-plugins-activity-pub", ["exports", "@ember/controller", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _controller, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsActivityPub extends _controller.default {
    newActor = (() => _activityPubActor.newActor)();
  }
  _exports.default = AdminPluginsActivityPub;
});