define("discourse/plugins/discourse-activity-pub/discourse/widgets/post-activity-pub-indicator", ["@ember/string", "discourse/widgets/widget", "discourse-common/lib/icon-library", "I18n", "discourse/plugins/discourse-activity-pub/discourse/components/modal/activity-pub-post-info"], function (_string, _widget, _iconLibrary, _I18n, _activityPubPostInfo) {
  "use strict";

  (0, _widget.createWidget)("post-activity-pub-indicator", {
    tagName: "div.post-info.activity-pub",
    services: ["modal"],
    title(attrs) {
      let opts = {
        domain: attrs.post.activity_pub_domain,
        object_type: attrs.post.activity_pub_object_type
      };
      if (attrs.time) {
        opts.time = attrs.time.format("h:mm a, MMM D");
      }
      return _I18n.default.t(`post.discourse_activity_pub.title.${attrs.state}`, opts);
    },
    buildClasses(attrs) {
      let placeClass = attrs.post.activity_pub_local ? "local" : "remote";
      return [(0, _string.dasherize)(attrs.state), placeClass];
    },
    html(attrs) {
      let iconName = attrs.state === "not_published" ? "discourse-activity-pub-slash" : "discourse-activity-pub";
      return (0, _iconLibrary.iconNode)(iconName);
    },
    click() {
      this.modal.show(_activityPubPostInfo.default, {
        model: this.attrs
      });
    }
  });
});