define("discourse/plugins/discourse-activity-pub/discourse/lib/activity-pub-utilities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildHandle = buildHandle;
  function buildHandle(_ref) {
    let {
      actor,
      model,
      site
    } = _ref;
    if (!actor && !model || model && !site) {
      return undefined;
    } else {
      const username = actor ? actor.username : model.activity_pub_username;
      const domain = actor ? actor.domain : site.activity_pub_host;
      return `@${username}@${domain}`;
    }
  }
});