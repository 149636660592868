define("discourse/plugins/discourse-activity-pub/discourse/connectors/composer-action-after/activity-pub-category-status", ["exports", "discourse/plugins/discourse-activity-pub/discourse/models/activity-pub-actor"], function (_exports, _activityPubActor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function showStatus(attrs, component) {
    const actor = _activityPubActor.default.findByModel(attrs.model.get("category"), "category");
    return actor && actor.ready && attrs.model.get("action") === "createTopic" && component.site.activity_pub_publishing_enabled;
  }
  var _default = _exports.default = {
    shouldRender(_, ctx) {
      return ctx.site.activity_pub_enabled;
    },
    setupComponent(attrs, component) {
      component.set("showStatus", showStatus(attrs, component));
      attrs.model.addObserver("category", () => {
        if (this._state === "destroying") {
          return;
        }
        component.set("showStatus", showStatus(attrs, component));
      });
    }
  };
});