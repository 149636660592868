define("discourse/plugins/discourse-activity-pub/discourse/templates/connectors/extra-nav-item/activity-pub-navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ActivityPubNavItem @model={{this.model}} @modelType={{this.modelType}} />
  */
  {
    "id": "B1oIeMwm",
    "block": "[[[8,[39,0],null,[[\"@model\",\"@modelType\"],[[30,0,[\"model\"]],[30,0,[\"modelType\"]]]],null]],[],false,[\"activity-pub-nav-item\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/connectors/extra-nav-item/activity-pub-navigation.hbs",
    "isStrictMode": false
  });
});