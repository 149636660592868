define("discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-actor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <Discovery::Navigation
    @createTopic={{this.createTopic}}
    @canCreateTopicOnTag={{this.canCreateTopicOnTag}}
    @category={{this.category}}
    @tag={{this.tag}}
  />
  
  {{#if this.site.activity_pub_publishing_enabled}}
    <ActivityPubBanner @actor={{this.actor}} />
  {{/if}}
  
  <ActivityPubNav @actor={{this.actor}} @follow={{route-action "follow"}} />
  
  {{outlet}}
  */
  {
    "id": "JrAzt6ju",
    "block": "[[[8,[39,0],null,[[\"@createTopic\",\"@canCreateTopicOnTag\",\"@category\",\"@tag\"],[[30,0,[\"createTopic\"]],[30,0,[\"canCreateTopicOnTag\"]],[30,0,[\"category\"]],[30,0,[\"tag\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"activity_pub_publishing_enabled\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@actor\"],[[30,0,[\"actor\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,3],null,[[\"@actor\",\"@follow\"],[[30,0,[\"actor\"]],[28,[37,4],[\"follow\"],null]]],null],[1,\"\\n\\n\"],[46,[28,[37,6],null,null],null,null,null]],[],false,[\"discovery/navigation\",\"if\",\"activity-pub-banner\",\"activity-pub-nav\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-activity-pub/discourse/templates/activity-pub-actor.hbs",
    "isStrictMode": false
  });
});